import { mapGetters, mapMutations, mapState } from 'vuex';
import KnLocalAlert from '../../shared/components/KnLocalAlert.vue';
import KnTable from '../../shared/components/KnTable.vue';
import { paginationMixin } from '@/modules/shared/mixins/paginationMixin';
import { fetchIncomes } from '../helpers/incomeOptions';
import { getItem } from '../../../api/api-methods';
import { numberFormatMX, getFullName } from '../../shared/helpers/dataUtils';
import {
  canAdd,
  canChange,
  insufficientPermissionsMessage,
} from '../../shared/helpers/permissionsUtils';

export default {
  components: { KnLocalAlert, KnTable },
  mixins: [paginationMixin],
  data() {
    return {
      headers: [
        {
          text: 'Fecha',
          value: 'fecha',
          class: 'purple--text',
          sortable: false,
        },
        {
          text: 'Estatus',
          value: 'estatus.dato',
          class: 'purple--text',
          sortable: false,
        },
        {
          text: 'Alumno',
          value: 'nombre_alumno',
          class: 'purple--text',
          sortable: false,
        },
        {
          text: 'Concepto',
          value: 'concepto',
          class: 'purple--text',
          sortable: false,
        },
        {
          text: 'Facturado',
          value: 'facturado_texto',
          class: 'purple--text',
          sortable: false,
        },

        {
          text: 'Subtotal',
          value: 'subtotal',
          class: 'purple--text',
          sortable: false,
        },
        {
          text: 'Impuesto',
          value: 't_impuestos',
          class: 'purple--text',
          sortable: false,
        },
        {
          text: 'Penalización',
          value: 't_penalizaciones',
          class: 'purple--text',
          sortable: false,
        },
        {
          text: 'Descuento',
          value: 't_descuento',
          class: 'purple--text',
          sortable: false,
        },
        {
          text: 'Total',
          value: 'total',
          class: 'purple--text',
          sortable: false,
        },
        { text: '', value: 'acciones', sortable: false },
      ],
      items: [],
      // search: null,
      loading: false,
      itemToEdit: null,
      loadingDownload: false,
      /** Variables para alerta */
      errors: [],
      warnings: [],
      loadingAlert: false,
      showAlert: false,
      alertType: 'success',
      alertText: 'Estas a punto de "Corregir" un ingreso. Deseas continuar?',
      alertColor: 'error',
      /*********************** */
    };
  },
  computed: {
    ...mapState(['institutionId', 'search']),
    ...mapGetters(['isBasicEmployee', 'isSuperAdmin', 'userId']),
  },
  async created() {
    this.setIsLogin(false);
    await this.getPaginatedIncomes(1);
  },
  watch: {
    search: {
      async handler() {
        await this.getPaginatedIncomes(1);
      },
    },
    institutionId: {
      async handler() {
        this.setIsLogin(false);
        await this.getPaginatedIncomes(1);
      },
    },
  },
  methods: {
    ...mapMutations(['setIsLogin']),
    canAdd: canAdd,
    async getPaginatedIncomes(page = 1) {
      this.items = [];
      this.setPaginationPage(page);
      if (!this.loading) {
        this.loading = true;
        const { ok, data, message, count } = await fetchIncomes({
          institutionId: this.institutionId,
          categoryId: this.search,
          systemStatus: true,
          limit: this.pagination.limit,
          offset: this.pagination.offset,
        });
        if (ok) {
          this.setPaginationCount(count);
          const incomes = data;
          for (const income of incomes) {
            let fullName = 'No aplica';
            if (income.alumno) {
              const personalData = await getItem(
                `/app-personas/datos-personales/${income.alumno.datos_personales}`
              );
              fullName = personalData.e
                ? 'Sin nombre'
                : getFullName(personalData);
            }
            const creationDate = new Date(income.fecha_elaboracion);
            const item = {
              ...income,
              folio: income.id,
              fecha: creationDate.toLocaleDateString('es-MX'),
              concepto: income.categoria.dato,
              nombre_alumno: fullName,
              facturado_texto: income.facturado ? 'Sí' : 'No',
              subtotal: numberFormatMX.format(income.sub_total),
              t_penalizaciones: income.penalizacion_aplicada
                ? numberFormatMX.format(income.total_penalizaciones)
                : numberFormatMX.format('0.00'),
              t_impuestos: numberFormatMX.format(income.total_impuestos),
              t_descuento: income.descuento_aplicado
                ? numberFormatMX.format(income.total_descuento)
                : numberFormatMX.format('0.00'),
              total: numberFormatMX.format(income.total_ingreso),
            };
            this.items.push(item);
          }
        } else {
          console.log('No se pudieron obtener ingresos', message);
        }
        this.loading = false;
      }
    },
    openAlert(obj) {
      // console.log('Open modal', obj);

      if (canChange('ingreso')) {
        this.alertType = 'success';
        this.alertText =
          'Estas a punto de "Corregir" un ingreso. Deseas continuar?';
        this.alertColor = 'error';
        this.itemToEdit = this.items.find((i) => i.id === obj.itemId);
      } else {
        this.alertType = 'info';
        this.alertText = insufficientPermissionsMessage();
        this.alertColor = 'warning';
      }
      this.showAlert = true;
    },
    actionAlertBtn1() {
      this.closeAlert();
    },
    actionAlertBtn2() {
      this.showAlert = false;
      this.$router.push({
        name: 'Editar Ingresos',
        params: {
          entity: this.itemToEdit,
          readonly: false,
        },
      });
    },
    closeAlert() {
      this.itemToEdit = null;
      this.showAlert = false;
    },
  },
};
